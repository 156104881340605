// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-activiteit-tsx": () => import("./../../../src/templates/activiteit.tsx" /* webpackChunkName: "component---src-templates-activiteit-tsx" */),
  "component---src-templates-ervaring-tsx": () => import("./../../../src/templates/ervaring.tsx" /* webpackChunkName: "component---src-templates-ervaring-tsx" */),
  "component---src-templates-nieuws-tsx": () => import("./../../../src/templates/nieuws.tsx" /* webpackChunkName: "component---src-templates-nieuws-tsx" */),
  "component---src-templates-overview-tsx": () => import("./../../../src/templates/overview.tsx" /* webpackChunkName: "component---src-templates-overview-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-vacature-tsx": () => import("./../../../src/templates/vacature.tsx" /* webpackChunkName: "component---src-templates-vacature-tsx" */)
}

