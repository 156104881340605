import { Tree } from "@valu/trees"
import { graphql, useStaticQuery } from "gatsby"
import { toTrees } from "../helpers"
import { FooterMenuQuery } from "./__generated__/FooterMenuQuery"
import { MenuItems } from "./__generated__/MenuItems"

type Keys = "one" | "two"
export type Index = Keys | "all"

export type MenuTree = Tree<MenuItems>[]
export type AllFooterMenuTree = {
  one: MenuTree
  two: MenuTree
}

function useFooterMenu(index: Keys): MenuTree
function useFooterMenu(index: "all"): AllFooterMenuTree

function useFooterMenu(index: Index) {
  const { ONE, TWO } = useStaticQuery<FooterMenuQuery>(graphql`
    query FooterMenuQuery {
      ONE: wpMenu(locations: { eq: FOOTER_ONE }) {
        menuItems {
          nodes {
            ...MenuItems
          }
        }
      }
      TWO: wpMenu(locations: { eq: FOOTER_TWO }) {
        menuItems {
          nodes {
            ...MenuItems
          }
        }
      }
    }
  `)

  const one = toTrees(ONE?.menuItems?.nodes)
  const two = toTrees(TWO?.menuItems?.nodes)

  switch (index) {
    case "one":
      return one
    case "two":
      return two
    case "all":
    default:
      return {
        one,
        two,
      }
  }
}

export default useFooterMenu
