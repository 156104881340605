import IconButton from "@material-ui/core/IconButton"
import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import Modal from "@material-ui/core/Modal"
import Slide from "@material-ui/core/Slide"
import CloseIcon from "@material-ui/icons/CloseRounded"
import React from "react"
import styled from "styled-components"
import { getLinkProps } from "../../../helpers"
import useMainMenu from "../../../hooks/useMainMenu"
import Logo from "../../logo"
import MDDDLink from "../../mdddLink"

interface IProps {
  open: boolean
  close: () => void
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  outline: 0;
  background-color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  flex-direction: column;
`

const InnerWrapper = styled.div`
  position: absolute;
  top: 102px;
  left: 0;
  right: 0;
  bottom: 62px;
  display: flex;
  flex-direction: column;
  outline: 0;
  background-color: ${({ theme }) => theme.palette.background.paper};
  overflow-y: auto;

  box-shadow: inset 0px 3px 3px -2px rgb(0 0 0 / 20%),
    inset 0px 3px 4px 0px rgb(0 0 0 / 14%),
    inset 0px 1px 8px 0px rgb(0 0 0 / 12%),
    inset 0px -3px 3px -2px rgb(0 0 0 / 18%),
    inset 0px -3px 4px 0px rgb(0 0 0 / 12%),
    inset 0px -1px 8px 0px rgb(0 0 0 / 10%);

  padding-top: ${({ theme }) => theme.spacing(2)}px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    overflow-x: hidden;
  }
`

const LogoWrapper = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing(2.5)}px;
  left: ${({ theme }) => theme.spacing(2.5)}px;
  width: 175px;
  z-index: 10;
`

const CloseButton = styled(IconButton)`
  font-size: ${({ theme }) => theme.spacing(5)}px;
  color: ${({ theme }) => theme.palette.secondary.main};
  position: absolute;
  top: ${({ theme }) => theme.spacing(2)}px;
  right: ${({ theme }) => theme.spacing(1)}px;
  z-index: 10;
`

const MenuWrapper = styled(MenuList)`
  display: flex;
  flex-direction: column;
  width: fit-content;
  padding: ${({ theme }) => `0 ${theme.spacing(6)}px ${theme.spacing(1)}px`};
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding-left: 0;
    padding-bottom: 0;
  }
  li {
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
  }
`

const MenuBTN = styled(MenuItem)`
  padding: ${({ theme }) => `${theme.spacing(1)}px ${theme.spacing(2)}px`};
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 115%;
  font-weight: 800;
  letter-spacing: 0.1rem;
  justify-content: flex-start;

  &.no-bottom-padding {
    padding-bottom: 0;
  }
`

const ChildBTN = styled(MenuBTN)`
  font-weight: 500;
  padding-left: ${({ theme }) => theme.spacing(5)}px;
  padding-top: ${({ theme }) => theme.spacing(1)}px;
  padding-bottom: 0;
  text-transform: none;
`

const Footer = styled.div`
  margin-top: auto;
  width: 100%;
`

const Contact = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(2)}px;
  padding-bottom: ${({ theme }) => theme.spacing(1.25)}px;
`

const MobileMenuModal: React.FC<IProps> = ({ open, close }) => {
  const menu = useMainMenu()

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="open-menu"
      aria-describedby="mobile-menu"
    >
      <Slide in={open}>
        <Wrapper>
          <LogoWrapper>
            <Logo version="secondary" />
          </LogoWrapper>

          <CloseButton
            aria-label="close-menu"
            onClick={close}
            children={<CloseIcon fontSize="inherit" />}
          />

          <InnerWrapper>
            <MenuWrapper>
              {menu?.map(menuItem => {
                const hasChildren = menuItem?.children.length > 0
                const childNodes = menuItem?.children

                return [
                  <MenuBTN
                    key={menuItem?.node.id}
                    onClick={close}
                    {...getLinkProps(menuItem?.node.path)}
                    className={hasChildren ? `no-bottom-padding` : ``}
                  >
                    {String(menuItem?.node.label)}
                  </MenuBTN>,
                  hasChildren
                    ? childNodes?.map((childItem, i) => {
                        const length = Number(menuItem?.children?.length) - 1
                        const end = length === i

                        return [
                          <ChildBTN
                            key={childItem?.node.id}
                            onClick={close}
                            {...getLinkProps(childItem?.node.path)}
                          >
                            {String(childItem?.node.label)}
                          </ChildBTN>,
                          end ? <div style={{ minHeight: 12 }} /> : null,
                        ]
                      })
                    : null,
                ]
              })}
            </MenuWrapper>
          </InnerWrapper>
          <Footer>
            <Contact>
              <MDDDLink color="textPrimary" />
            </Contact>
          </Footer>
        </Wrapper>
      </Slide>
    </Modal>
  )
}

export default MobileMenuModal
