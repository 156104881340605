import { graphql, useStaticQuery } from "gatsby"
import { toTrees } from "../helpers"
import { MainMenuQuery } from "./__generated__/MainMenuQuery"

export default () => {
  const data = useStaticQuery<MainMenuQuery>(graphql`
    fragment MenuItems on WpMenuItem {
      id
      label
      parentId
      path
    }
    query MainMenuQuery {
      wpMenu(locations: { eq: MAIN_MENU }) {
        menuItems {
          nodes {
            ...MenuItems
          }
        }
      }
    }
  `)

  return toTrees(data.wpMenu?.menuItems?.nodes)
}
