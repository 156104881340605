import styled from "styled-components"
import { complexTransition } from "../../../theme/globalStyles"

interface SpacerProps {
  trigger: boolean
}

const Spacer = styled.div<SpacerProps>`
  height: ${({ trigger }) => (trigger ? 223 : 162)}px;
  ${complexTransition}

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    height: ${({ trigger }) => (trigger ? 183 : 143)}px;
  }
`

export default Spacer
