import { graphql, useStaticQuery } from "gatsby"
import { LatLngTuple } from "leaflet"
import React from "react"
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet"
import styled from "styled-components"
import { isServer } from "../../../../helpers"
import { borderRadius } from "../../../../theme/globalStyles"
import { NAWQuery } from "./__generated__/NAWQuery"

const StyledMapContainer = styled(MapContainer)`
  width: 100%;
  height: 225px;
  ${borderRadius};
  box-shadow: ${({ theme }) => theme.shadows[3]};
`

const Map: React.FC = () => {
  if (isServer()) return null

  const data = useStaticQuery<NAWQuery>(graphql`
    query NAWQuery {
      wp {
        contactInfo {
          straat
          postcode
          plaats
          huisnummer
          latitude
          longitude
        }
      }
    }
  `)

  const { straat, huisnummer, postcode, plaats, latitude, longitude } = {
    ...data.wp?.contactInfo,
  }
  if (!latitude || !longitude) return null

  const position: LatLngTuple = [parseFloat(latitude), parseFloat(longitude)]

  return (
    <StyledMapContainer center={position} zoom={13} scrollWheelZoom>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Marker position={position}>
        <Popup>
          {straat} {huisnummer} <br />
          {postcode} {plaats}
        </Popup>
      </Marker>
    </StyledMapContainer>
  )
}

export default Map
