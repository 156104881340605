import React from "react"
import styled from "styled-components"
import useMainMenu from "../../../hooks/useMainMenu"
import MenuItem from "./menuItem"
import MobileMenu from "./mobileMenu"

const MenuWrapper = styled.nav`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    display: none;
  }
`

const Menu: React.FC = () => {
  const menu = useMainMenu()

  return (
    <>
      <MenuWrapper>
        {menu.map(item => (
          <MenuItem key={item.node.id} item={item} />
        ))}
      </MenuWrapper>
      <MobileMenu />
    </>
  )
}

export default Menu
