import { alpha, darken } from "@material-ui/core/styles"
import { createGlobalStyle, css } from "styled-components"

export const complexTransition = css`
  transition: all ${({ theme }) => theme.transitions.duration.complex}ms
    ease-in-out;
`

export const borderRadius = css`
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
`

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) =>
      alpha(theme.palette.primary.light, 0.14)};
    overflow-x: hidden;
  }

  *::selection { 
    color: ${({ theme }) => theme.palette.secondary.contrastText};
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }

  //
  // scrollbar styling
  //
  * {
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) =>
      `${theme.palette.secondary.dark} ${theme.palette.secondary.light}`};
  }
  *::-webkit-scrollbar {
    width: ${({ theme }) => `${theme.spacing(2)}px`};
  }
  *::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.palette.secondary.light};  
  }
  *::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.secondary.dark};   
    border: ${({ theme }) =>
      `${theme.spacing(0.5)}px solid ${theme.palette.secondary.light}`}; 
    border-radius: 8px;  
    ${complexTransition}  
  }
  *::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }) =>
      darken(theme.palette.secondary.dark, 0.14)};     
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    *::-webkit-scrollbar {
      width: 0;
    }
  }

  ul {
    max-width: 668px;
    padding-left: ${({ theme }) => theme.spacing(8)}px;

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
      padding-left: ${({ theme }) => theme.spacing(5)}px;
    }

    li {
      + li {
        margin-top: ${({ theme }) => theme.spacing(1)}px;
      }
      ::marker {
        color: ${({ theme }) => theme.palette.primary.main};
      }
    }
  }
  ul.MuiMenu-list {
    width: 100%;
    max-width: 100%;
  }
`
