import Button, { ButtonProps } from "@material-ui/core/Button"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Grow from "@material-ui/core/Grow"
import MuiMenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import Paper from "@material-ui/core/Paper"
import Popper from "@material-ui/core/Popper"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import { Tree } from "@valu/trees"
import React, { forwardRef, useEffect, useRef } from "react"
import styled from "styled-components"
import { getLinkProps } from "../../../helpers"
import useBoolState from "../../../hooks/useBoolState"
import { MainMenuQuery_wpMenu_menuItems_nodes } from "../../../hooks/__generated__/MainMenuQuery"

interface IProps {
  item: Tree<MainMenuQuery_wpMenu_menuItems_nodes>
}

type MenuItemType = React.FC<IProps>

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  padding: ${({ theme }) => `${theme.spacing(2)}px ${theme.spacing(2)}px`};
  font-size: 130%;
  border-radius: 0;
`
const StyledButtonWithRef = forwardRef<HTMLInputElement, ButtonProps>(
  (props, ref) => <StyledButton {...props} innerRef={ref} />
)

const SubMenu = styled(Paper)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`

const SubMenuItem = styled(MuiMenuItem)`
  font-size: 120%;
`

const SingleMenuItem: MenuItemType = ({ item }) => (
  <StyledButton {...getLinkProps(item.node.path)}>
    {item.node.label}
  </StyledButton>
)

const MultipleMenuItem: MenuItemType = ({ item }) => {
  const [open, { handleOpen, handleClose, toggle }] = useBoolState()
  const ref = useRef(null)
  const scrolled = useScrollTrigger({ threshold: 5 })

  useEffect(() => {
    handleClose()
  }, [scrolled])

  return (
    <>
      <StyledButtonWithRef
        {...getLinkProps(item.node.path)}
        ref={ref}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        onClick={toggle}
      >
        {item.node.label}
      </StyledButtonWithRef>

      <Popper open={open} transition disablePortal anchorEl={ref.current}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <SubMenu elevation={3} onMouseEnter={handleOpen}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList onMouseLeave={handleClose}>
                  {item.children.map(item => (
                    <SubMenuItem
                      key={item.node.id}
                      onClick={handleClose}
                      {...getLinkProps(item.node.path)}
                    >
                      {item.node.label}
                    </SubMenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </SubMenu>
          </Grow>
        )}
      </Popper>
    </>
  )
}

const MenuItem: MenuItemType = ({ item }) => {
  if (item.children.length > 0) {
    return <MultipleMenuItem item={item} />
  } else {
    return <SingleMenuItem item={item} />
  }
}

export default MenuItem
