import { createTheme } from "@material-ui/core/styles"

const useTheme = () =>
  createTheme({
    palette: {
      primary: {
        main: "#EE7F00",
        light: "#FDB94D",
        contrastText: "#FFF",
      },
      secondary: {
        main: "#8671AC",
        contrastText: "#FFF",
      },
    },
    typography: {
      h1: {
        fontSize: 50,
      },
      h2: {
        fontSize: 38,
      },
      h3: {
        fontSize: 32,
      },
      h4: {
        fontSize: 28,
      },
    },
    shape: {
      borderRadius: 6,
    },
  })

export default useTheme
