import { graphql, useStaticQuery } from "gatsby"
import { SocialMediaQuery } from "../__generated__/graphql-gatsby"

const useSocials = () => {
  const query = useStaticQuery<SocialMediaQuery>(graphql`
    query SocialMedia {
      wp {
        contactInfo {
          facebook
          instagram
          linkedin
          pinterest
          youtube
        }
      }
    }
  `)

  return query.wp?.contactInfo || {}
}

export default useSocials
