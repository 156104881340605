import React from "react"
import styled from "styled-components"
import useFooterMenu from "../../../hooks/useFooterMenu"
import PartnerLogos from "../../logo/partnerLogos"
import BottomFooter from "./bottomFooter"
import Map from "./map"
import MenuList, { StyledMenuList } from "./menuList"
import Socials from "./socials"

export type Area = "ONE" | "TWO" | "THREE" | "FOUR" | "MAP"

const OuterWrapper = styled.footer`
  margin-top: auto;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  box-shadow: ${({ theme }) => theme.shadows[7]};
`

const MenuWrapper = styled.div`
  display: grid;
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing(6)}px ${theme.spacing(4)}px 0`};
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas:
    "ONE TWO THREE FOUR"
    "MAP MAP MAP MAP";
  grid-gap: ${({ theme }) => theme.spacing(2)}px;
  grid-row-gap: ${({ theme }) => theme.spacing(4)}px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.xl}px) {
    grid-template-areas: "ONE TWO TWO TWO THREE FOUR MAP MAP MAP MAP";
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: 1fr;
    grid-gap: ${({ theme }) => theme.spacing(4)}px;
    grid-row-gap: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.xl}px) {
    grid-template-areas: "ONE TWO TWO TWO THREE FOUR MAP MAP MAP";
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: 1fr;
    grid-gap: ${({ theme }) => theme.spacing(4)}px;
    grid-row-gap: 0;
  }

  @media (max-width: 1680px) {
    grid-template-areas: "ONE TWO TWO TWO THREE FOUR MAP MAP";
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 1fr;
    grid-row-gap: 0;
  }

  @media (max-width: 1440px) {
    grid-template-areas: "ONE TWO THREE FOUR MAP";
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    grid-row-gap: 0;
  }

  @media (max-width: 1360px) {
    grid-template-areas:
      "ONE TWO TWO TWO THREE FOUR"
      "MAP MAP MAP MAP MAP MAP";
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr auto;
    grid-row-gap: ${({ theme }) => theme.spacing(4)}px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    grid-template-areas:
      "ONE TWO TWO"
      "MAP MAP MAP"
      "THREE FOUR FOUR";
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr auto 1fr;
  }

  @media (max-width: 400px) {
    grid-template-areas:
      "ONE ONE"
      "TWO TWO"
      "MAP MAP"
      "THREE FOUR";
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr) auto repeat(1, 1fr);
  }
`

const Footer: React.FC = () => {
  const { one, two } = useFooterMenu("all")

  return (
    <OuterWrapper>
      <MenuWrapper>
        <MenuList area="ONE" menu={one} />
        <MenuList area="TWO" menu={two} />
        <PartnerLogos
          area="THREE"
          spacing={4}
          logos={[
            { key: "kwf", url: "https://www.kwf.nl/" },
            { key: "ipso", url: "https://www.ipso.nl/" },
          ]}
        />
        <PartnerLogos
          area="FOUR"
          logos={[{ key: "anbi", url: "https://www.anbi.nl/" }]}
        />
        <StyledMenuList area="MAP">
          <Map />
        </StyledMenuList>
      </MenuWrapper>
      <Socials />
      <BottomFooter />
    </OuterWrapper>
  )
}

export default Footer
