import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image"
import React from "react"
import { LogoQueryQuery } from "../../__generated__/graphql-gatsby"

export type LogoProps = Omit<GatsbyImageProps, "image" | "alt">
export type ILogo = React.FC<
  LogoProps & {
    version?: "default" | "white" | "secondary"
  }
>

const Logo: ILogo = props => {
  const query = useStaticQuery<LogoQueryQuery>(graphql`
    fragment LogoData on File {
      childImageSharp {
        gatsbyImageData(
          transformOptions: { fit: CONTAIN }
          placeholder: BLURRED
        )
      }
    }
    query LogoQuery {
      default: file(relativePath: { eq: "logo.png" }) {
        ...LogoData
      }
      white: file(relativePath: { eq: "logo-white.png" }) {
        ...LogoData
      }
      secondary: file(relativePath: { eq: "logo-secondary.png" }) {
        ...LogoData
      }
    }
  `)
  const { version, ...imgProps } = props

  const file = version ? query[version] : query.default
  const image = file?.childImageSharp?.gatsbyImageData

  return (
    <GatsbyImage
      {...imgProps}
      image={image}
      alt="logo"
      style={{ maxWidth: 300 }}
    />
  )
}

export default Logo
