import loadable from "@loadable/component"
import { IconButton } from "@material-ui/core"
import React from "react"
import styled from "styled-components"
import useSocials from "../../../hooks/useSocials"
const FacebookIcon = loadable(() => import("@material-ui/icons/Facebook"))
const InstagramIcon = loadable(() => import("@material-ui/icons/Instagram"))
const LinkedInIcon = loadable(() => import("@material-ui/icons/LinkedIn"))
const PinterestIcon = loadable(() => import("@material-ui/icons/Pinterest"))
const YouTubeIcon = loadable(() => import("@material-ui/icons/YouTube"))

type ButtonProps = {
  url: string
  className?: string | undefined
}
const Button: React.FC<ButtonProps> = ({ children, url, className }) => (
  <div>
    <IconButton
      component="a"
      href={url}
      target="_blank"
      className={className}
      color="inherit"
    >
      {children}
    </IconButton>
  </div>
)

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: ${({ theme }) => `${theme.spacing(3)}px 0`};

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding-top: 0;
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    + div {
      :before {
        font-size: 0.95rem;
        font-weight: bold;
        content: "◯";
        margin: ${({ theme }) => `0 ${theme.spacing(2)}px`};
        color: ${({ theme }) => theme.palette.primary.light};

        @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
          margin: ${({ theme }) => `0 ${theme.spacing(1)}px`};
        }
      }
    }
  }
`

const Socials: React.FC = () => {
  const { facebook, instagram, linkedin, pinterest, youtube } = useSocials()

  return (
    <Wrapper>
      {facebook && (
        <Button url={facebook}>
          <FacebookIcon fontSize="large" />
        </Button>
      )}
      {instagram && (
        <Button url={instagram}>
          <InstagramIcon fontSize="large" />
        </Button>
      )}
      {linkedin && (
        <Button url={linkedin}>
          <LinkedInIcon fontSize="large" />
        </Button>
      )}
      {pinterest && (
        <Button url={pinterest}>
          <PinterestIcon fontSize="large" />
        </Button>
      )}
      {youtube && (
        <Button url={youtube}>
          <YouTubeIcon fontSize="large" />
        </Button>
      )}
    </Wrapper>
  )
}

export default Socials
