import Button, { ButtonProps } from "@material-ui/core/Button"
import React from "react"
import styled from "styled-components"
import { complexTransition } from "../../../theme/globalStyles"

type IProps = {
  trigger: boolean
}

type StyledProps = IProps & ButtonProps<"a">

const StyledButton = styled(({ trigger, ...rest }: StyledProps) => (
  <Button {...rest} component="a" />
))`
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 0;
  border-top-left-radius: ${({ trigger, theme }) =>
    trigger ? theme.shape.borderRadius : 0}px;
  padding: ${({ trigger, theme }) =>
    `${theme.spacing(trigger ? 1.25 : 4)}px ${theme.spacing(5)}px}`};
  ${complexTransition};

  @media (max-width: 750px) {
    display: none;
  }
`

const Donate: React.FC<IProps> = ({ trigger }) => (
  <StyledButton
    variant="contained"
    color="secondary"
    size="large"
    disableElevation
    trigger={trigger}
    href="https://tikkie.me/pay/Stichti6922/9neysphmBep23PZaBU76X9"
    target="_blank"
    rel="noopener"
  >
    DONEER NU
  </StyledButton>
)

export default Donate
