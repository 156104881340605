import { globalHistory } from "@reach/router"
import React, { createContext, useEffect, useState } from "react"
import useCheckUndefinedContext from "../hooks/useCheckUndefinedContext"
import ThemeWrapper from "../theme/themeWrapper"
import Layout from "./layout"

const Context = createContext<string | undefined>(undefined)

export const useNavStateContext = () => useCheckUndefinedContext(Context)

const WrapRoot: React.FC = ({ children }) => {
  const [lastNavState, setLastNavState] = useState("")

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      setLastNavState(action)
    })
  }, [])

  return (
    <ThemeWrapper>
      <Context.Provider value={lastNavState}>
        <Layout>{children}</Layout>
      </Context.Provider>
    </ThemeWrapper>
  )
}

export default WrapRoot
