import Container from "@material-ui/core/Container"
import React from "react"
import styled from "styled-components"
import Footer from "./footer"
import Header from "./header"

interface IProps {}

const Wrapper = styled(Container)`
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const Layout: React.FC<IProps> = ({ children }) => {
  return (
    <Wrapper maxWidth="xl" disableGutters>
      <Header />
      {children}
      <Footer />
    </Wrapper>
  )
}

export default Layout
