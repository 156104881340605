import AppBar from "@material-ui/core/AppBar"
import Toolbar, { ToolbarProps } from "@material-ui/core/Toolbar"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { complexTransition } from "../../../theme/globalStyles"
import Logo from "../../logo"
import Donate from "./donate"
import Menu from "./menu"
import Spacer from "./spacer"

type TopWrapperProps = {
  trigger: boolean
} & ToolbarProps

type LogoWrapperProps = {
  trigger: boolean
}

const Wrapper = styled(AppBar)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const TopWrapper = styled(({ trigger, ...rest }: TopWrapperProps) => (
  <Toolbar {...rest} />
))`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.paper};
  height: ${({ trigger }) => (trigger ? 160 : 85)}px;
  ${complexTransition}

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    height: ${({ trigger, theme }) =>
      trigger ? theme.spacing(15) : theme.spacing(10)}px;
  }
`
const LogoWrapper = styled.div<LogoWrapperProps>`
  width: 100%;
  max-width: ${({ trigger }) => (trigger ? 300 : 165)}px;
  ${complexTransition}

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    max-width: ${({ trigger }) => (trigger ? 200 : 125)}px;
  }
`

const Header: React.FC = () => {
  const trigger = useScrollTrigger({
    threshold: 100,
    disableHysteresis: true,
  })
  return (
    <>
      <Wrapper>
        <TopWrapper trigger={!trigger} disableGutters>
          <LogoWrapper trigger={!trigger}>
            <Link to="/">
              <Logo />
            </Link>
          </LogoWrapper>
          <Donate trigger={!trigger} />
        </TopWrapper>
        <Menu />
      </Wrapper>
      <Spacer trigger={!trigger} />
    </>
  )
}

export default Header
