import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/MenuRounded"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import MobileMenuModal from "./mobileMenuModal"

interface IProps {}

const MenuWrapper = styled.nav`
  display: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 63px;
  }
`

const MenuButton = styled(IconButton)`
  display: inline-flex;
  font-size: ${({ theme }) => theme.spacing(5)}px;
  color: ${({ theme }) => theme.palette.secondary.main};
  opacity: 0;
  transition: ${({ theme }) =>
    theme.transitions.create("opacity", {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.short,
    })};

  &.loaded {
    opacity: 1;
  }
`

const MobileMenu: React.FC<IProps> = () => {
  const [open, setOpen] = useState(false)
  const [className, setClassName] = useState("menu-btn")

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  useEffect(() => {
    setClassName("menu-btn loaded")
  }, [])

  return (
    <MenuWrapper>
      <MenuButton
        children={<MenuIcon fontSize="inherit" />}
        onClick={handleOpen}
        aria-label="open-menu"
        className={className}
      />

      <MobileMenuModal open={open} close={handleClose} />
    </MenuWrapper>
  )
}

export default MobileMenu
