import { flatListToTrees } from "@valu/trees"
import { Link, navigate } from "gatsby"
import { MenuItems } from "./hooks/__generated__/MenuItems"

export type ThreeNodes = Maybe<Array<Maybe<MenuItems>>>
export const toTrees = (nodes: ThreeNodes) =>
  nodes
    ? flatListToTrees(nodes, {
        getId: item => item?.id,
        getParentId: item => item?.parentId,
      })
    : []

export const isExternalUrl = (url: Maybe<string>) =>
  !!url && url.startsWith("http")

export function getLinkProps(URL: string | null, append: any = {}) {
  const url = String(URL)
  const isExternal = isExternalUrl(url)

  return {
    component: isExternal ? "a" : Link,
    ...(isExternal ? { href: url } : { to: url }),
    ...append,
  }
}

export const isServer = () => typeof window === "undefined"
export const isClient = () => typeof window !== "undefined"

export const redirect = (url: Maybe<string>) => {
  if (!url) return

  if (isExternalUrl(url)) {
    window.location.href = url
  } else {
    navigate(url)
  }
}

export function createPhoneLink(tel: string): string {
  let number = tel
  if (tel.charAt(0) === "0") {
    number = `+31${tel.substring(1)}`
  }

  return `tel:${number}`
}

export const wpDateFormat = "D MMMM YYYY"

export type HasCommonElements = (
  arr1: Maybe<any[]>,
  arr2: Maybe<any[]>
) => boolean
export const hasCommonElements: HasCommonElements = (arr1, arr2) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false

  return arr1.some(item => arr2.includes(item))
}

export const flatten = (el: any) => el.id
