import Button from "@material-ui/core/Button"
import React from "react"
import styled from "styled-components"
import { Area } from "."
import { getLinkProps } from "../../../helpers"
import { MenuTree } from "../../../hooks/useFooterMenu"

interface IMenuListArea {
  area: Area
}

interface IMenuList extends IMenuListArea {
  menu: MenuTree
}

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  padding: ${({ theme }) => `${theme.spacing(0.5)}px ${theme.spacing(1)}px`};
  width: 100%;
  max-width: ${({ theme }) => theme.spacing(26)}px;
  font-weight: 300;
  font-size: 110%;
  display: inline;
  text-transform: none;
`

export const StyledMenuList = styled.div<IMenuListArea>`
  grid-area: ${({ area }) => area};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: ${({ area }) => (area === "MAP" ? "center" : "flex-start")};
`

const MenuList: React.FC<IMenuList> = ({ area, menu }) => (
  <StyledMenuList area={area}>
    {menu.map(item => (
      <StyledButton key={item.node.id} {...getLinkProps(item.node.path)}>
        {item.node.label}
      </StyledButton>
    ))}
  </StyledMenuList>
)

export default MenuList
