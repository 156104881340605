import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import MDDDLink from "../../mdddLink"
import { FooterNAWQuery } from "./__generated__/FooterNAWQuery"

const Wrapper = styled.div`
  padding: ${({ theme }) => `${theme.spacing(2)}px ${theme.spacing(4)}px`};
  background-color: ${({ theme }) => theme.palette.primary.dark};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  display: flex;
  justify-contentent: flex-start;

  ${({ theme }) => `${theme.breakpoints.down("sm")}`} {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)}px;
  }
`

const Spacer = styled.span`
  padding: ${({ theme }) => `0 ${theme.spacing(2)}px`};
  ${({ theme }) => `${theme.breakpoints.down("sm")}`} {
    display: none;
  }
`
const AccountNumber = styled.span`
  margin-left: auto;
  ${({ theme }) => `${theme.breakpoints.down("sm")}`} {
    margin-left: 0;
  }
`

const BottomFooter: React.FC = () => {
  const data = useStaticQuery<FooterNAWQuery>(graphql`
    query FooterNAWQuery {
      wp {
        contactInfo {
          straat
          postcode
          plaats
          huisnummer
        }
      }
    }
  `)
  const { straat, postcode, plaats, huisnummer } = { ...data.wp?.contactInfo }
  return (
    <Wrapper>
      {`${straat} ${huisnummer},  ${postcode}  ${plaats}`}
      <Spacer>|</Spacer>
      <MDDDLink color="inherit" />
      <AccountNumber>NL92 ABNA 0466 9422 57</AccountNumber>
    </Wrapper>
  )
}

export default BottomFooter
