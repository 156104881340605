import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { PartnerLogosQuery } from "../../__generated__/graphql-gatsby"
import { Area } from "../layout/footer"

type Logo = {
  key: "kwf" | "anbi" | "ipso"
  url: string
}

interface IProps {
  area: Area
  logos: Array<Logo>
  spacing?: number
}

interface WrapperProps {
  area: Area
}

const Wrapper = styled.div<WrapperProps>`
  grid-area: ${({ area }) => area};
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Img = styled(GatsbyImage)`
  max-width: 156px;
`

const A = styled.a<{ spacing: Maybe<number> }>`
  + * {
    margin-top: ${({ theme, spacing }) =>
      theme.spacing(spacing ? spacing : 2)}px;
  }
`

const PartnerLogos: React.FC<IProps> = ({ area, logos, spacing }) => {
  const query = useStaticQuery<PartnerLogosQuery>(graphql`
    fragment PartnerLogo on File {
      childImageSharp {
        gatsbyImageData(transformOptions: { fit: CONTAIN })
      }
    }
    query PartnerLogos {
      anbi: file(relativePath: { eq: "anbi.png" }) {
        ...PartnerLogo
      }
      ipso: file(relativePath: { eq: "ipso.png" }) {
        ...PartnerLogo
      }
      kwf: file(relativePath: { eq: "kwf.png" }) {
        ...PartnerLogo
      }
    }
  `)

  const props = {
    target: "_blank",
    rel: "noopener",
  }

  return (
    <Wrapper area={area}>
      {logos.map((logo, i) => (
        <A
          key={`${logo.key}-${i}`}
          href={logo.url}
          spacing={spacing}
          {...props}
        >
          <Img
            image={query[logo.key]?.childImageSharp?.gatsbyImageData}
            alt="logo"
          />
        </A>
      ))}
    </Wrapper>
  )
}

export default PartnerLogos
